var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "select_opt_area",
      attrs: { id: "oog_area_" + _vm.cntrSeq },
    },
    [
      _c("div", { staticClass: "select_opt_area mt10" }, [
        _c("table", { staticClass: "tbl_col t2" }, [
          _vm._m(0),
          _c("tbody", [
            _vm._m(1),
            _c("tr", [
              _c(
                "td",
                { staticClass: "vat" },
                [
                  _c("e-input-number", {
                    attrs: {
                      "is-comma": true,
                      "input-class": "col_8",
                      id: "orgLength_" + _vm.cntrSeq,
                      maxlength: 10,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.checkSize("orgLength")
                      },
                    },
                    model: {
                      value: _vm.oogInfo.orgLength,
                      callback: function ($$v) {
                        _vm.$set(_vm.oogInfo, "orgLength", $$v)
                      },
                      expression: "oogInfo.orgLength",
                    },
                  }),
                  _vm._v(" ㎝ "),
                  _vm.overLength !== "" && _vm.overLength > 0
                    ? _c("div", { staticClass: "txt_size" }, [
                        _vm._v(
                          " Over Size: (F) +" +
                            _vm._s(_vm.setComma(_vm.overLength)) +
                            "㎝"
                        ),
                        _c("br"),
                        _vm._v(
                          "(B) +" + _vm._s(_vm.setComma(_vm.overLength)) + "㎝ "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "td",
                { staticClass: "vat" },
                [
                  _c("e-input-number", {
                    attrs: {
                      "is-comma": true,
                      "input-class": "col_8",
                      id: "orgWidth_" + _vm.cntrSeq,
                      maxlength: 10,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.checkSize("orgWidth")
                      },
                    },
                    model: {
                      value: _vm.oogInfo.orgWidth,
                      callback: function ($$v) {
                        _vm.$set(_vm.oogInfo, "orgWidth", $$v)
                      },
                      expression: "oogInfo.orgWidth",
                    },
                  }),
                  _vm._v(" ㎝ "),
                  _vm.overWidth !== "" && _vm.overWidth > 0
                    ? _c("div", { staticClass: "txt_size" }, [
                        _vm._v(
                          " Over Size: (L) +" +
                            _vm._s(_vm.setComma(_vm.overWidth)) +
                            "㎝"
                        ),
                        _c("br"),
                        _vm._v(
                          "(R) +" + _vm._s(_vm.setComma(_vm.overWidth)) + "㎝ "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "td",
                { staticClass: "vat" },
                [
                  _c("e-input-number", {
                    attrs: {
                      "is-comma": true,
                      "input-class": "col_8",
                      id: "orgHeight_" + _vm.cntrSeq,
                      maxlength: 10,
                    },
                    on: {
                      input: function ($event) {
                        _vm.checkSize("orgHeight")
                        _vm.checkValidationOOG("orgHeight")
                      },
                    },
                    model: {
                      value: _vm.oogInfo.orgHeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.oogInfo, "orgHeight", $$v)
                      },
                      expression: "oogInfo.orgHeight",
                    },
                  }),
                  _vm._v(" ㎝ "),
                  _vm.overHeight !== "" && _vm.overHeight > 0
                    ? _c("div", { staticClass: "txt_size" }, [
                        _vm._v(
                          " Over Size: +" +
                            _vm._s(_vm.setComma(_vm.overHeight)) +
                            "㎝ "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "td",
                { staticClass: "vat" },
                [
                  _c("e-input-number", {
                    attrs: {
                      id: "frgtWt_" + _vm.cntrSeq,
                      "is-comma": true,
                      "input-class": "col_8",
                      maxlength: 13,
                    },
                    on: {
                      input: function ($event) {
                        _vm.checkWeight($event, _vm.cntrSeq)
                        _vm.checkValidationOOG("frgtWt")
                      },
                    },
                    model: {
                      value: _vm.oogInfo.frgtWt,
                      callback: function ($$v) {
                        _vm.$set(_vm.oogInfo, "frgtWt", $$v)
                      },
                      expression: "oogInfo.frgtWt",
                    },
                  }),
                  _vm._v(" kg "),
                ],
                1
              ),
              _c("td", { staticClass: "vat tac" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.oogInfo.tareWtYn,
                      expression: "oogInfo.tareWtYn",
                    },
                  ],
                  attrs: {
                    id: "tareWtYn_" + _vm.cntrSeq,
                    type: "checkbox",
                    "true-value": "Y",
                    "false-value": "N",
                  },
                  domProps: {
                    checked: _vm.oogInfo.tareWtYn == "Y",
                    checked: Array.isArray(_vm.oogInfo.tareWtYn)
                      ? _vm._i(_vm.oogInfo.tareWtYn, null) > -1
                      : _vm._q(_vm.oogInfo.tareWtYn, "Y"),
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.oogInfo.tareWtYn,
                          $$el = $event.target,
                          $$c = $$el.checked ? "Y" : "N"
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.oogInfo,
                                "tareWtYn",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.oogInfo,
                                "tareWtYn",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.oogInfo, "tareWtYn", $$c)
                        }
                      },
                      function ($event) {
                        return _vm.handleChange()
                      },
                    ],
                  },
                }),
                _c("label", { attrs: { for: "tareWtYn_" + _vm.cntrSeq } }),
              ]),
              _c("td", { attrs: { rowspan: "3" } }, [
                _c("div", { staticClass: "img_simulation" }, [
                  _c("div", { class: "div_simulation div_" + _vm.simulation }),
                ]),
                _vm.$ekmtcCommon.isNotEmpty(_vm.deadSpace)
                  ? _c("p", { staticClass: "text_center mt5" }, [
                      _vm._v("Dead Space : "),
                      _c("span", { staticClass: "font_medium" }, [
                        _vm._v(_vm._s(_vm.deadSpace) + "TEU"),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._m(2),
            _c("tr", [
              _c(
                "td",
                [
                  _c("e-input-number", {
                    attrs: {
                      id: "itemQty_" + _vm.cntrSeq,
                      "is-comma": true,
                      "input-class": "itemQty",
                      maxlength: 13,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.checkValidationOOG("itemQty")
                      },
                    },
                    model: {
                      value: _vm.oogInfo.itemQty,
                      callback: function ($$v) {
                        _vm.$set(_vm.oogInfo, "itemQty", $$v)
                      },
                      expression: "oogInfo.itemQty",
                    },
                  }),
                ],
                1
              ),
              _c("td", [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.oogInfo.underDckCd,
                        expression: "oogInfo.underDckCd",
                      },
                    ],
                    attrs: { id: "underDckCd_" + _vm.cntrSeq },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.oogInfo,
                            "underDckCd",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.handleChange()
                        },
                      ],
                    },
                  },
                  _vm._l(_vm.unserDckList, function (cvo) {
                    return _c(
                      "option",
                      { key: cvo.cd, domProps: { value: cvo.cd } },
                      [_vm._v(_vm._s(cvo.cdNm))]
                    )
                  }),
                  0
                ),
              ]),
              _c("td", [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.oogInfo.bulkYn,
                        expression: "oogInfo.bulkYn",
                      },
                    ],
                    attrs: { id: "bulkYn_" + _vm.cntrSeq },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.oogInfo,
                            "bulkYn",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.handleChange()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.062"))),
                    ]),
                    _c("option", { attrs: { value: "Y" } }, [_vm._v("Yes")]),
                    _c("option", { attrs: { value: "N" } }, [_vm._v("No")]),
                  ]
                ),
              ]),
              _c(
                "td",
                { attrs: { colspan: "2" } },
                [
                  _c("e-auto-complete-commodity", {
                    attrs: {
                      "ctr-cd": _vm.memberDetail.userCtrCd,
                      "cd-nm": _vm.oogInfo.cmdtDsc,
                      "focus-force": true,
                      id: "cmdtDsc_" + _vm.cntrSeq,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handleCallbackChange("cmdtDsc", ...arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [_vm._v(" File Upload ")]),
              _c("td", { attrs: { colspan: "2" } }, [
                _c("div", { staticClass: "filebox" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button sm",
                      class: _vm.isFileUpload() ? "blue" : "gray file",
                      on: {
                        click: function ($event) {
                          return _vm.openUploadPopup()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.205")) + " ")]
                  ),
                ]),
              ]),
              _c("th", { attrs: { scope: "col" } }, [_vm._v(" Best Lashing ")]),
              _c("td", { attrs: { colspan: "3" } }, [
                _c(
                  "div",
                  {
                    staticClass: "filebox down_btn_area",
                    on: {
                      click: function ($event) {
                        return _vm.oogFileDown(_vm.oogInfo.cmdtCd)
                      },
                    },
                  },
                  [
                    _c("label", { attrs: { for: "file" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.206"))),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [
                _vm._v(" Request Remark "),
              ]),
              _c("td", { attrs: { colspan: "5" } }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.oogInfo.respCont,
                      expression: "oogInfo.respCont",
                    },
                  ],
                  domProps: { value: _vm.oogInfo.respCont },
                  on: {
                    change: _vm.handleChange,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.oogInfo, "respCont", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("tr", [
              _c("td", { attrs: { colspan: "6" } }, [
                _c("div", { staticClass: "desc_list mt0" }, [
                  _c("ul", [
                    _c("li", [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.207"))),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.207_1"))),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.207_2"))),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.208"))),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.208_1")) + " "),
                    ]),
                    _c("li", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.209")))]),
                    _c("li", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.210")))]),
                  ]),
                ]),
              ]),
            ]),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [_vm._v("Status")]),
              _c("td", { attrs: { colspan: "2" } }, [
                _c("span", { staticClass: "span_scg" }, [
                  _vm._v(_vm._s(_vm.oogInfo.apvStsNm)),
                ]),
              ]),
              _c("th", { attrs: { scope: "row" } }, [_vm._v("Ref No.")]),
              _c("td", { attrs: { colspan: "2" } }, [
                _c("span", { staticClass: "span_scg" }, [
                  _vm._v(_vm._s(_vm.oogInfo.scgAppNo)),
                ]),
              ]),
            ]),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [_vm._v("KMTC Remark")]),
              _c("td", { attrs: { colspan: "5" } }, [
                _c("textarea", {
                  staticClass: "txt_border_none",
                  attrs: { readonly: "" },
                  domProps: { value: _vm.oogInfo.apvCont },
                }),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "win-layer-pop",
        [
          _vm.uploadComponent
            ? _c(_vm.uploadComponent, {
                tag: "component",
                attrs: {
                  "parent-info": _vm.uploadParam,
                  "category-info": _vm.uploadCategoryInfo,
                },
                on: {
                  close: _vm.closeUploadPopup,
                  "set-dext-file-info": _vm.uploadCallbackFunc,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "13%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Length")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Width")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Height")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Cargo Weight")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Tare Included")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("SIMULATION")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { scope: "col" } }, [_vm._v("ITEM Q’TY")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Stowage Request")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Break Bulk")]),
      _c("th", { attrs: { scope: "col", colspan: "2" } }, [
        _vm._v("Commodity"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }